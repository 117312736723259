<template>
  <div>
    <action-header
        :is-loading="!inventoryDocument"
        :actions-model="headerModel"
        :page="page"
    />

    <div class="q-px-xs q-py-md">
      <form-builder :schema="schema" />

    </div>

    <div v-if="inventoryDocument && inventoryDocument.id">
      <q-separator />
      <q-card-section>
          <q-table
                  class="sticky-header-table"
                  row-key="id"
                  :rows-per-page-label="$t('Rows per page')"
                  :rows="items"
                  :columns="columns"
                  v-model:pagination="pagination"
                  :rows-per-page-options="[5, 10, 25, 50, 100, 150, 200, 250]"
                  @request="onRequest"
                  virtual-scroll
                  binary-state-sort
                  flat
          >
              <template v-slot:loading>
                  <q-inner-loading
                          showing
                          color="primary"
                  />
              </template>

              <template v-slot:body="props">
                  <q-tr
                      :props="props"
                      class="clickable"
                  >
                      <q-td
                              key="id"
                              :props="props"
                              class="text-subtitle1"
                      >
                          <strong>{{ props.row.id }}</strong>
                      </q-td>

                      <q-td
                              key="state"
                              :props="props"
                              class="text-subtitle1"
                      >
                          <q-badge
                                  :color="stateColor[props.row.state]"
                                  :label="$t(props.row.state)"
                          />
                      </q-td>

                      <q-td
                              key="executive"
                              :props="props"
                      >
                        <span v-if="props.row._embedded && props.row._embedded.executive">
                          {{ props.row._embedded.executive.name }}
                        </span>

                          <span v-else>--</span>
                      </q-td>

                      <q-td
                              key="place"
                              :props="props"
                      >
                          <span v-if="props.row._embedded?.place" @dblclick="handleOpenLocation(props.row)">
                            <new-picking-place
                                :data="props.row._embedded.place"
                                :type="props.row._embedded.place.type"
                                badge
                            />
                          </span>

                          <span v-else>--</span>
                      </q-td>

                      <q-td
                          key="product"
                          :props="props"
                          style="white-space: pre-wrap"
                      >
                        <span v-if="props.row._embedded && props.row._embedded.productOffer" style="white-space: pre-wrap">
                          {{ `${props.row._embedded.productOffer.name} (${props.row._embedded.productOffer.id})` }}
                        </span>

                          <span v-else>
                            <q-btn
                                color="light-blue-9"
                                size="sm" icon="add"
                                :label="$t('Item')"
                                @click="openList(props.row)"
                            />
                          </span>
                      </q-td>

                      <q-td
                              key="collision"
                              :props="props"
                      >
                          <q-chip v-if="ifCollision(props.row)" clickable square icon="error_outline" color="red" text-color="white" @click="handleCollisionClick(props.row)">{{ $t(`Attention`) }}</q-chip>
                          <q-tooltip v-if="ifCollision(props.row)">Click to Fix collusion</q-tooltip>
                      </q-td>

                      <q-td
                          key="barcode"
                          :props="props"
                          auto-width
                      >
                          <span v-if="props.row.instructions && props.row.instructions.length > 0">
                            <div
                                v-for="instruction in props.row.instructions"
                                :key="instruction"
                                class="col-4 q-pa-sm"
                            >
                              <div   v-if="instruction && instruction.data && instruction.data.barcode">
                                  <div
                                      v-for="barcode in instruction.data.barcode"
                                      :key="barcode"
                                      class="col-4 q-pa-sm"
                                  >
                                  {{barcode}}
                                  <br>
                                  </div>
                              </div>

                            </div>
                          </span>
                          <span v-else-if="props.row.eav && props.row.eav['barcode']">
                              {{props.row.eav['barcode']}}
                          </span>

                          <span v-else>--</span>
                      </q-td>

                      <q-td
                              key="shop"
                              :props="props"
                              auto-width
                      >
                          <span v-if="props.row._embedded && props.row._embedded.shop">
                            {{ props.row._embedded.shop.name }}
                          </span>

                          <span v-else>--</span>
                      </q-td>
                      <q-td
                              key="expected"
                              :props="props"
                              auto-width
                      >
                          <strong>
                              {{props.row.count}}
                          </strong>

                      </q-td>

                      <q-td
                              key="found"
                              :props="props"
                              auto-width
                      >
                          <editable-input-field
                                  :value="props.row.scannedCount"
                                  :update-function="newValue => handleScannedCountUpdate(newValue, props.row)"
                                  :rules="[
                                    (val) => /^[0-9]\d*$/.test(val),
                                  ]"
                          ></editable-input-field>
<!--                          <strong>-->
<!--                              {{props.row.scannedCount}}-->
<!--                          </strong>-->

                      </q-td>

                      <q-td
                              key="less"
                              :props="props"
                              auto-width
                              :class='`${differenceLess(props.row)} text-white`'
                      >
                          <div v-if="props.row.scannedCount - props.row.count < 0">
                              <strong>
                                  {{ calculate(props.row)}}
                              </strong>
                          </div>
                          <div v-else>
                              <strong>
                                  {{ 0 }}
                              </strong>

                          </div>

                      </q-td>
                      <q-td
                              key="more"
                              :props="props"
                              auto-width
                              :class="`${differenceMore(props.row)} text-white`"
                      >
                          <div v-if="props.row.scannedCount - props.row.count > 0">
                              <strong>
                                  {{ calculate(props.row) }}
                              </strong>
                          </div>
                          <div v-else>
                              <strong>
                                  {{ 0 }}
                              </strong>
                          </div>
                      </q-td>

                  </q-tr>
              </template>
              <template v-slot:bottom-row v-if="items.length>0">
                  <q-tr>
                      <q-td class="border-top border-right text-center">
                          {{ $t('Total') }}
                      </q-td>

                      <q-td class="border-top" />

                      <q-td class="border-top" />

                      <q-td class="border-top" />

                      <q-td class="border-top" />

                      <q-td class="border-top" />

                      <q-td class="border-top" />

                      <q-td class="border-top" />

                      <q-td class="border-top text-center">
                          <strong>
                              {{ totalExpected }}
                          </strong>

                      </q-td>

                      <q-td class="border-top text-center">
                          <strong>
                              {{ totalFound }}
                          </strong>
                      </q-td>

                      <q-td class=" bg-negative border-top text-center border-left border-right text-white">
                          <strong>
                              {{ totalNegative }}
                          </strong>
                          <!--                <strong>-->
                          <!--                  {{ totalFound - totalExpected }}-->
                          <!--                </strong>-->
                      </q-td>
                      <q-td class="bg-positive border-top text-center border-left border-right text-white">
                          <strong>
                              {{ totalPositive }}
                          </strong>
                      </q-td>
                  </q-tr>
              </template>
          </q-table>
      </q-card-section>
    </div>

    <sticky-bottom-header
        :is-loading="isSaveLoading"
        :is-active="hasChange"
        @back="handleDiscard"
        @save="save"
    />
  </div>
  <q-dialog
      v-model="isOpened"
      :maximized="true"
  >
    <products-grid
        v-if="isOpened"
        :options="options"
        @submit="handleSubmit"
        @close="closeList"
    />
  </q-dialog>
  <revision-collusion-modal ref="revisionCollusion" @submit="editCount"/>
</template>

<script>
// Vuex
import { mapGetters, mapMutations, mapActions } from 'vuex'

// Components
import ActionHeader from '../../components/action-header/ActionHeader'

// Helpers
import { buildQuery } from '../../utils/query-utils'
import NewPickingPlace from "@/apps/app/components/picking/new-picking-place.vue";
import EditableInputField from "@/apps/app/components/delivery-services/EditableInputField.vue";
import RevisionCollusionModal from "@/apps/app/components/modals/RevisionCollusionModal.vue";
import ProductsGrid from "@/apps/app/components/products/ProductsGrid.vue";

export default {
  name: 'InventoryDocument',
  components: {
    ProductsGrid,
    RevisionCollusionModal,
    EditableInputField,
    NewPickingPlace,
    ActionHeader,
  },
  data () {
    return {
      isSaveLoading: false,
      hasChange: false,
      warehouse: null,
      isOpened: false,
      taskToEdit: null,
      types: [
        { id: 'classic', name: 'Classic' },
        { id: 'by_place', name: 'By Place' }
      ],
      stateColor: {
        new: 'bg-lime-5 text-white',
        confirmed: 'bg-lime-4 text-white',
        complete: 'bg-lime-3 text-white',
        rejected: 'bg-red-4 text-dark'
      },
      filter: '',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      columns: [
        {
          label: this.$t('Id'),
          name: 'id',
          align: 'left'
        },
        {
          label: this.$t('Status'),
          name: 'state',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Executive'),
          name: 'executive',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Location'),
          name: 'place',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Product'),
          name: 'product',
          align: 'left'
        },
        {
          label: '',
          name: 'collision',
          align: 'left'
        },
        {
          label: this.$t('Barcode'),
          name: 'barcode',
          align: 'left'
        },
        {
          label: this.$t('Shop'),
          name: 'shop',
          align: 'left',
          sortable: true
        },
        {
          label: this.$t('Expected Quantity'),
          name: 'expected',
          align: 'center'
        },
        {
          label: this.$t('Found Quantity'),
          name: 'found',
          align: 'center'
        },
        {
          label: this.$t('Less'),
          name: 'less',
          align: 'center'
        },
        {
          label: this.$t('More'),
          name: 'more',
          align: 'center'
        }
      ],
      isRequestedTask: false,
      isLoading: false,
      items: [],
      type: null,
      totalExpected: 0,
      totalFound: 0,
      totalPositive: 0,
      totalNegative: 0
    }
  },
  computed: {
    ...mapGetters([
      'inventoryDocument',
      'inventoryDocumentsLoading'

    ]),
    options () {
      return {
        onlyOneShop: true,
        onlyOneProduct: true,
        allWarehouses: true,
        noWarehouseFilter: true,
        disabledWarehouse: true,
        selectItem: true,
        defaultValues: {
          warehouse: this.warehouse
        }
      }
    },
    page () {
      return {
        id: this.inventoryDocument && this.inventoryDocument.id,
        name: this.inventoryDocument && this.inventoryDocument.id
            ? this.$t('Shipping manifest')
            : this.$t('New shipping manifest')
      }
    },
    headerModel () {
      if (!this.inventoryDocument) {
        return []
      }

      const newState = { id: 'new', title: this.$t('New'), transitions: ['confirm'], color: 'grey-4' }
      const activeState = { id: 'active', title: this.$t('Active'), transitions: ['confirm'], color: 'amber' }
      const confirmState = { id: 'confirm', title: this.$t('Confirm'), transitions: [], color: 'teal' }
      const confirmedState = { id: 'confirmed', title: this.$t('Confirmed'), transitions: [], color: 'teal' }
      const inactiveState = { id: 'inactive', title: this.$t('Inactive'), transitions: ['confirm'], color: 'grey-4' }
      const deletedState = { id: 'deleted', title: this.$t('Deleted'), transitions: [], color: 'danger' }

      const states = [
        { buttons: [newState, activeState, confirmState,confirmedState, inactiveState, deletedState] }
      ]

      const option = {
        id: 'statuses',
        type: 'super-dropdown',
        variant: 'link',
        color: states[0].buttons[0].color,
        editableFields: false,
        onClick: (action) => {
          return this.$service.storageInventoryDocuments.save({ action }, this.inventoryDocument.id)
              .then(inventoryDocument => {
                this.setInventoryDocument(inventoryDocument)
                this.upsertInventoryDocuments(inventoryDocument)
              })
        },
        value: states[0].buttons[0],
        options: states
      }

      const dropdown = {
        section: 'Dropdown',
        className: 'text-center q-pb-sm fit--mobile',
        options: [
          option
        ]
      }

      if (this.inventoryDocument && this.inventoryDocument.state !== option.value.id) {
        states.find(group => {
          let status = group.buttons.find(btn => {
            return btn.id === this.inventoryDocument.state
          })

          if (status) {
            option.color = group.color || status.color
            option.value = status
          }

          return status
        })
      }

      return [
        {
          section: 'BackAction',
          className: 'q-pr-sm hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'q-pa-sm fit--mobile text-min-content',
          options: [
            {
              id: 'title',
              className: 'text-subtitle1 row justify-center',
              valueClassName: 'col-12 text-right--mobile q-px-xs',
              valueStyle: 'white-space: nowrap',
              type: 'text',
              value: this.inventoryDocument && this.inventoryDocument.id
                  ? this.$t('Inventory Document ID') + ': ' + this.inventoryDocument.id
                  : this.$t('New inventory document')
            }
          ]
        },
        {
          section: 'Actions',
          className: 'col-12 col-sm text-center row items-center justify-center q-gutter-y-xs q-py-xs',
          options: [
            {
              id: 'services',
              wrapperClassName: 'q-px-xs',
              wrapperStyle: 'max-width: 120px;',
              type: 'button',
              variant: 'light',
              disabled: !this.items,
              label: this.$t('Open sequence'),
              onClick: () => {
                if(this.items[0] && this.items[0]._embedded && this.items[0]._embedded.queue && this.items[0]._embedded.sequence) {
                  this.setPickingQueue(this.items[0]._embedded.queue)
                  const path = `/workstation/queues/${this.items[0]._embedded.queue.adapter}/entity/${this.items[0]._embedded.queue.id}`
                  this.$router.push({ path, query: { objectSequence: this.items[0]._embedded.sequence.id } });
                }
              }
            },
          ]
        },
        dropdown
      ]
    },
    schema () {
      return {
        isLoading: !this.inventoryDocument,
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'multiselect',
                label: this.$t('Warehouse'),
                field: 'warehouse',
                value: this.warehouse,
                disabled: !!this.inventoryDocument && !!this.inventoryDocument.id,
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                required: true,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { field: 'state', type: 'eq', value: 'active' }
                    ]
                  }

                  return this.$service.warehouse.getAll(query)
                },
                onChange: warehouse => {
                  this.warehouse = warehouse
                  this.hasChange = true
                  this.updateInventoryDocument({'warehouse':this.warehouse.id})
                }
              },
              {
                if: false,
                type: 'select',
                label: this.$t('Type'),
                value: this.type,
                customLabel: (row) => {
                  return row && typeof row === 'object'
                      ? this.$t(row.name)
                      : row
                },
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                options: this.types,
                required: true,
                disabled: !!this.inventoryDocument && !!this.inventoryDocument.id,
                onChange: (type) => {
                  this.type = type
                  this.hasChange = true
                  this.updateInventoryDocument({'type':this.type.id})
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('ExtId'),
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                field: 'extId',
                disabled: !!this.inventoryDocument && !!this.inventoryDocument.id,
                value: this.inventoryDocument && this.inventoryDocument.extId,
                onChange: extId => {
                  this.hasChange = true
                  this.updateInventoryDocument({ extId })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Comment'),
                wrapperStyleClasses: 'col-12 col-sm-6 q-pa-xs',
                field: 'comment',
                disabled: false,
                value: this.inventoryDocument && this.inventoryDocument.comment,
                onChange: comment => {
                  this.hasChange = true
                  this.updateInventoryDocument({ comment })
                }
              },
            ]
          }
        ]
      }
    }
  },
  mounted () {
    if (this.$route.params.id) {
      const pagination = {
        per_page: 25,
        page: 1,
        filter: [],
      }

      this.onRequest({ pagination })
    }

    if (!this.inventoryDocument && this.$route.params.id) {
      this.loadInventoryDocument(this.$route.params.id)
    }

    if (!this.$route.params.id) {
      this.setNewInventoryDocument()
    }
  },
  unmounted () {
    this.setInventoryDocument(null)
  },
  methods: {
    ...mapActions([
      'loadInventoryDocument',
      'saveInventoryDocument',
      'loadPreprocessingTaskQuery'
    ]),
    ...mapMutations([
      'setPickingQueue',
      'updateInventoryDocument',
      'addErrorNotification',
      'setNewInventoryDocument',
      'setInventoryDocument',
      'updateInventoryDocumentEmbedded',
      'upsertInventoryDocument'
    ]),
    calculate (item) {
      return item.scannedCount - item.count
    },
    ifCollision (item) {
      if(item.eav && item.eav['storage-revision-storage-location-collusion'] && item.eav['storage-revision-storage-location-collusion'] === 'collusion_found') {
        return true
      }
      return false
    },
    handleCollisionClick (item) {
      this.$refs.revisionCollusion.show(item)
    },
    onRequest (data = {}) {
      this.isLoading = true
      const query = buildQuery({})

      // query.criteria = {
      //   id: this.$route.params.id
      // }
      query.filter = [
        {
          field: 'inventory',
          type: 'eq',
          value: this.$route.params.id
        },
      ]

      return this.$service.storageTasksFinishedLocation.getAll(query)
          .then(({ page, totalItems, items }) => {
            if(items.length === 0) {
              return
            }
            const sequences = items.reduce((acc,e)=>{
              if(e._embedded && e._embedded.sequence && !acc.includes(e._embedded.sequence.id)) {
                acc.push(e._embedded.sequence.id)
              }
              return acc
            },[])
            if (sequences.length === 0) {
              return
            }

            this.pagination = data.pagination || {}
            const query = buildQuery(this.pagination)

            if (!query.page) {
              query.page = 1
            }

            if (!query.per_page) {
              query.per_page = 250
            }

            if (!query.filter) {
              query.filter = []
            }

            query.filter = [
              ...query.filter,
              ...this.filter
            ]

            if (!query['order-by']) {
              query['order-by'] = [
                { type: 'field', field: 'created', direction: 'desc' }
              ]
            }


            query.filter.push({ type: 'in', field: 'sequence', values: sequences})


            this.isTasksLoading = true

            return this.$service.pickingTask.getAll(query)
              .then(({ items, totalItems, page, totalPages }) => {
                this.pagination = {
                  page,
                  ...this.pagination,
                  rowsNumber: totalItems
                }

                this.totalPages = totalPages
                this.totalExpected = 0
                this.totalFound = 0
                this.totalPositive = 0
                this.totalNegative = 0
                if(Array.isArray(items)) {
                  items.forEach(e => {
                    if (!e.scannedCount && e.count) {
                      this.totalExpected += e.count
                    } else if (!e.count && e.scannedCount) {
                      this.totalFound += e.scannedCount
                    } else {
                      this.totalFound += e.scannedCount
                      this.totalExpected += e.count
                    }
                  })
                }


                this.items = items
              })
              .then(() => {
                if(this.items) {
                  this.items.forEach( e => {
                    let result = e.scannedCount - e.count
                    if (result > 0 ) {
                      this.totalPositive = this.totalPositive + result
                    } else if (result < 0) {
                      this.totalNegative = result + this.totalNegative
                    }
                  })
                }

                if (this.inventoryDocument?._embedded?.warehouse) {
                  return this.$service.warehouse.get(this.inventoryDocument._embedded.warehouse._links.self.href)
                    .then(warehouse => {
                      this.warehouse = warehouse

                      return warehouse
                    })
                }
              })
          })

          .finally(() => {
            this.isLoading = false
          })
    },
    handleDiscard () {
      this.$router.go()
    },
    handleBack () {
      this.$router.back()
    },
    differenceLess (item) {
      if ((item.scannedCount - item.count ) < 0 ) {
        return 'bg-negative'
      }
      return 'bg-dark'
    },
    differenceMore (item) {
      if((item.scannedCount - item.count) > 0) {
          return 'bg-positive'
      }
      return 'bg-dark'
    },
    save () {
      this.isSaveLoading = true
      if(!this.$route.params.id) {
        this.updateInventoryDocument({ state: 'new' })
      }
      this.saveInventoryDocument()
          .then(() => {
            this.hasChange = false
            if (!this.$route.params.id) {
              this.handleBack()
            }
          })
          .finally(() => {
            this.isSaveLoading = false
          })
    },
    editCount (quantity, item) {
      if(quantity !== item.scannedCount) {
        const result = {
          scannedCount: quantity,
          eav: {['storage-revision-storage-location-collusion'] : "collusion_confirmed"}
        }
        return this.$service.pickingTask.save(result, item.id)
          .then(()=> {
            this.onRequest()
          })
      } else {
        const result = {
          eav: {['storage-revision-storage-location-collusion'] : "collusion_confirmed"}
        }
        return this.$service.pickingTask.save(result, item.id)
          .then(()=> {
            this.onRequest()
          })
      }
    },
    handleScannedCountUpdate (quantity ,item ) {
      const result = {
        scannedCount: quantity
      }
      return this.$service.pickingTask.save(result, item.id)
        .then(()=> {
          this.onRequest()
        })
    },
    handleOpenLocation (item) {
      if(item._embedded.place) {
        window.open(`/#/storage/locations/entity/${item._embedded.place.id}`, '_blank')
      }
    },
    openList (task) {
      this.taskToEdit = task
      this.isOpened = true
    },
    closeList () {
      this.isOpened = false
      this.taskToEdit = null
    },
    handleSubmit (data) {
      let id = 0
      let shop = 0
      if (data.products) {
        let product = data.products[0]
        if(product?._embedded?.productOffer) {
          id = product._embedded.productOffer.id
        }
        if (data.shop) {
          shop = data.shop.id
        } else if (product?._embedded?.shop) {
          shop = product._embedded.shop.id
        }
      }
      const result = {
        productOffer: {
          id: id,
          shop: shop
        }
      }
      return this.$service.pickingTask.save(result, this.taskToEdit.id)
        .then(()=> {
          this.taskToEdit = null
          this.onRequest()
          this.closeList()
        })
    },
  }
}
</script>
